import { FC, useEffect, useRef, useState } from 'react'

import { styled } from '@liftfoils/styles'
import { SectionVideoStoriesProps } from '@liftfoils/models'

import {
  Link,
  Button,
  PortableText,
  Gradient,
  Media,
} from '@liftfoils/components'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  $posterHeight: true,
  display: 'grid',
})
const Background = styled('div', {
  display: 'grid',
  gridRow: 1,
  gridColumn: 1,
  zIndex: 0,
})

const Overlay = styled('div', {
  zIndex: 2,
  gridRow: 1,
  gridColumn: 1,
  position: 'relative',
  display: 'grid',
  gridGap: '$8',
  gridTemplateRows: '1fr min-content 1fr',
  $py: 'M',
  px: '$containerMargin',
})
const Title = styled('h2', {
  textAlign: 'center',
  lift_font: 'caps02',
  color: 'white',
})
const Text = styled('div', {
  textAlign: 'center',
  lift_font: 'heading03',
  maxWidth: '540px',
  color: 'white',
  '.highlighted': { color: '$white_06' },
})
const Content = styled('div', {
  gridRow: 2,
  display: 'grid',
  gridGap: '$8',
  justifyItems: 'center',
  alignSelf: 'center',
})
const Status = styled('div', {
  gridRow: 3,
  gridTemplateColumns: 'repeat(3, 1fr)',
  width: '100%',
  gridGap: '$4',
  display: 'grid',
  alignSelf: 'end',
  '@lg': {
    gridGap: '$8',
  },
})
const Progress = styled('div', {
  mt: '$5',
  width: '0',
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  bg: 'white',
  color: 'white',
  height: '3px',
  borderRadius: 0,
})
const ProgressTrack = styled('div', {
  bg: '$white_06',
  height: '1px',
  position: 'absolute',
  width: '100%',
  bottom: '1px',
})
const StoryTitle = styled('div', {
  position: 'relative',
  bg: 'transparent',
  lift_font: 'heading03',
  color: '$white_06',
  textAlign: 'left',
  '.highlighted': {
    color: 'white',
  },
  '.hide-on-mobile': {
    display: 'none',
    '@lg': {
      display: 'block',
    },
  },
  variants: {
    hidden: {
      true: { display: 'none' },
    },
  },
})
const StatusWrap = styled('div', {
  display: 'grid',
  gridRow: 3,
  alignSelf: 'end',
})
const StatusMobile = styled('div', {
  '@lg': {
    display: 'none',
  },
})
const VideoWrap = styled('div', {
  gridArea: '1/1',
  width: '100%',
  height: '100%',
  variants: {
    isVisible: {
      true: {
        opacity: '1',
      },
      false: {
        opacity: '0',
      },
    },
  },
})

export const SectionVideoStories: FC<SectionVideoStoriesProps> = ({
  title,
  text,
  stories,
  buttonLink,
  buttonLabel,
}) => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0)

  const statusRef = useRef<HTMLDivElement | null>(null)
  const backgroundRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const currentVideoElem =
      backgroundRef?.current?.querySelectorAll('video')[currentStoryIndex]
    if (!(statusRef?.current && backgroundRef?.current && currentVideoElem))
      return
    currentVideoElem.currentTime = 0
    // setup progress bars
    const progressBars: NodeListOf<HTMLDivElement> =
      statusRef?.current.querySelectorAll(`.Progress`)

    progressBars.forEach((bar, i) => {
      bar.style.width = i < currentStoryIndex ? '100%' : '0'
    })

    const interval = setInterval(() => {
      const currentVideoProgressBar = progressBars[currentStoryIndex]
      const currentVideoDuration = currentVideoElem.duration
      const currentVideoTime = currentVideoElem.currentTime

      if (!currentVideoProgressBar || !currentVideoDuration) return
      const progressVal = currentVideoTime / currentVideoDuration
      if (progressVal) {
        currentVideoProgressBar.style.width = `${progressVal * 100}%`
      }
    }, 10)

    return () => {
      clearInterval(interval)
    }
  }, [currentStoryIndex])

  const onStoryPlaybackFinish = () => {
    if (stories && currentStoryIndex !== stories.length - 1) {
      setCurrentStoryIndex((prevIndex) =>
        prevIndex === stories.length - 1 ? prevIndex : prevIndex + 1,
      )
    }
  }
  return (
    <Wrap>
      <Background ref={backgroundRef}>
        {stories &&
          stories.map(
            (s, i) =>
              s.video && (
                <VideoWrap
                  key={`video-stories-wrap-${i}`}
                  isVisible={i === currentStoryIndex}
                  className={i === currentStoryIndex ? 'current' : ''}
                >
                  <Media
                    {...s.video}
                    layout={'fill'}
                    onPlaybackFinish={onStoryPlaybackFinish}
                    isVideoPaused={i !== currentStoryIndex}
                    loop={false}
                    hardcropRatio={{
                      '856': 'square',
                      '1440': 'landscape2',
                      '1920': 'landscape2',
                      '2880': 'landscape1',
                    }}
                    alt={s.video?.alt ?? ''}
                  />
                </VideoWrap>
              ),
          )}
        <Gradient from={'bottom'} css={{ height: '40%' }} />
      </Background>
      <Overlay>
        <Content>
          <Title>{title}</Title>
          <Text>
            <PortableText value={text} />
          </Text>
          {validateLink(buttonLink) && buttonLabel && (
            <Link size={'medium'} appearance={'solidWhite'} {...buttonLink}>
              {buttonLabel}
            </Link>
          )}
        </Content>

        <StatusWrap>
          <StatusMobile>
            {stories?.map((s, i) => {
              return (
                <StoryTitle
                  key={`video-stories-title-${i}`}
                  hidden={i !== currentStoryIndex}
                >
                  <PortableText value={s.title} />
                </StoryTitle>
              )
            })}
          </StatusMobile>
          <Status ref={statusRef}>
            {stories?.map((s, i) => {
              return (
                <Button
                  key={`video-stories-control-button-${i}`}
                  onClick={() => {
                    setCurrentStoryIndex(i)
                  }}
                  appearance={'gridStretch'}
                >
                  <StoryTitle>
                    <div className={'hide-on-mobile'}>
                      <PortableText value={s.title} />
                    </div>
                    <Progress className={'Progress'} />
                    <ProgressTrack />
                  </StoryTitle>
                </Button>
              )
            })}
          </Status>
        </StatusWrap>
      </Overlay>
    </Wrap>
  )
}
